// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/styles/main.scss';

import Header from './components/Header/Header';
import VowGlow from '../src/pages/VowGlow';
import FlexFit from '../src/pages/FlexFit';
import Trello from '../src/pages/Trello';
import Spotify from '../src/pages/Spotify';
import Home from './../src/pages/Home';
import Footer from './components/Footer/Footer';
import arrowTop from './assets/images/icon-arrow-top.svg';

function App() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const handleScrollToTop = () => {

    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  const handleScroll = () => {
    const scrollThreshold = 200;
    const shouldShowScrollToTop = window.scrollY > scrollThreshold;
    setShowScrollToTop(shouldShowScrollToTop);
  };

  useEffect(() => {
    const setBodyContainerHeight = () => {
      const wrapper = document.querySelector('.wrapper');
      const footer = document.querySelector('footer');

      if (wrapper && footer) {
        const footerHeight = footer.offsetHeight;
        const windowHeight = window.innerHeight;

        wrapper.style.minHeight = `${windowHeight - footerHeight}px`;
      }
    };

    const handleResize = () => {
      setBodyContainerHeight();
    };

    setBodyContainerHeight();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // Add scroll event listener


    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);

    };
  }, []);



  return (
    <Router>
      <div className="App">
        <Header />
        <div className="body-container">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  onMount={() => console.log('Home component mounted')} // Add this line
                />
              }
            />
            <Route
              path="/vowglow"
              element={
                <VowGlow
                  onMount={() => console.log('FlexFit component mounted')} // Add this line
                />
              }
            />
            <Route
              path="/flex-fit"
              element={
                <FlexFit
                  onMount={() => console.log('FlexFit component mounted')} // Add this line
                />
              }
            />
            <Route
              path="/trello"
              element={
                <Trello
                  onMount={() => console.log('FlexFit component mounted')} // Add this line
                />
              }
            />
            <Route
              path="/spotify"
              element={
                <Spotify
                  onMount={() => console.log('FlexFit component mounted')} // Add this line
                />
              }
            />
          </Routes>
        </div>
        {showScrollToTop && (
          <a className="scroll-to-top" onClick={handleScrollToTop}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#000000"></path> </g></svg>
          </a>
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
