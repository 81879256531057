// FlexFit.js
import React from 'react';
import Sidebar from '../components/FlexFit/Sidebar';
// Import your image
import vowglowimage from '../assets/images/vowglowimage.png';
import vowglowdesign from '../assets/images/flexfit-design-process.png';
import vowglowcompetitive from '../assets/images/vowglowCompetitive.png';
import vowglowSWOT from '../assets/images/vowglowSWOT.png';
import vowglowProposal from '../assets/images/vowglowProposal.png';
import vowglowMindmapping from '../assets/images/vowglowMindmapping.png';
import vowglowUserjourney from '../assets/images/vowglowUserjourney.png';
import vowglowPersona from '../assets/images/vowglowPersona.png';
import vowglowFindings from '../assets/images/vowglowFindings.png';
import vowglowUserflow from '../assets/images/vowglowUserflow.png';
import vowglowLowFi from '../assets/images/vowglowLowfi.png';
import vowglowDesignsystem from '../assets/images/vowglowDesignsystem.png';
import vowglowMidFi from '../assets/images/vowglowMidfi.png';
import vowglowResults from '../assets/images/vowglowResults.png';
import iteration1 from '../assets/images/vowglowIteration1.png';
import iteration2 from '../assets/images/vowglowIteration2.png';
import iteration3 from '../assets/images/vowglowIteration3.png';
import iteration4 from '../assets/images/vowglowIteration4.png';
import iteration5 from '../assets/images/vowglowIteration5.png';
import vowglownextstep from '../assets/images/vowglowNextstep.png';


const VowGlow = () => {
    const headerHeight = 84;
    const sidebarItems = [
        'Overview',
        'Problem',
        'Project Kickoff',
        'Research',
        'Design',
        'Iteration',
        'Product',
        'Next Step'
    ];

    return (
        <div className="flex-fit work-page">
            <div className="work-hero">
                <img src={vowglowimage} />
            </div>

            <div className="sidebar sidebar-sticky">
                <Sidebar items={sidebarItems} />
            </div>
            <div className="work-body" >
                <section key={0} className="section">
                    <div className='text-container'>

                        <h2 id="overview" className="section-title" style={{ color: '#FF3E55' }}>Overview</h2>
                        <h3>VowGlow</h3>
                        <p>VowGlow is a comprehensive <b>wedding planning app</b> designed to simplify the wedding planning process and enhance the overall experience for engaged couples. With VowGlow, users can seamlessly navigate every aspect of their wedding journey, from initial inspiration to final celebration, all in one convenient app.</p>
                        <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name" style={{ color: '#FF3E55', fontSize: '20px' }}>Timeline</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>8 Weeks</li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name" style={{ color: '#FF3E55', fontSize: '20px' }}>Role</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>UX Research</li>
                                    <li>UX Design</li>
                                    <li>Wireframing</li>
                                    <li>Prototyping</li>
                                    <li>Project Management</li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name" style={{ color: '#FF3E55', fontSize: '20px' }}>Tools</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Figma</li>
                                    <li>Asana</li>
                                    <li>Google Sheets/Slides</li>
                                    <li>MindMeister</li>
                                </ul>
                            </div>
                        </div>
                        <h3>Project Objective</h3>
                        <p>Design a user-friendly mobile wedding planning app that addresses key challenges in efficiently organizing weddings, finding reliable vendors, and managing budgets and timelines. The app will provide a comprehensive platform that streamlines the entire planning process.</p>
                        <h3>Design Thinking Process</h3>
                        <div className="image-inside">
                            <img src={vowglowdesign} />
                        </div>
                    </div>
                </section>

                <section key={1} className="section lightpink-bg">
                    <div className='text-container'>
                        <h2 id="problem" className="section-title" style={{ color: '#FF3E55' }}>Problem</h2>
                        <p></p>
                        <p style={{ paddingTop: '30px' }}><b>The problem</b> we aim to solve involves addressing the complex challenges individuals face when organizing weddings, while also aligning with the business’s objectives of expanding market presence and driving revenue growth.</p>
                        <p><b>Our mission</b> is to simplify the wedding planning journey, enhance user experience, and support the business in achieving financial success.</p>

                    </div>
                </section>

                <section key={2} className="section">
                    <div className='text-container'>
                        <h2 id="project-kickoff" className="section-title" style={{ color: '#FF3E55' }}>Project Kickoff</h2>
                        <p></p>
                        <p>
                            I began by crafting a detailed <b>Project Proposal Idea Sketch.</b>
                            <span className="figure" > (Figure 1)</span>
                            {' '} This sketch served as a foundational document that outlined various project ideas, assumptions, and goals, setting the stage for the development process.
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowProposal}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 1 (Project Proposal Idea Sketch)
                            </p>
                        </div>


                        <p>
                            
                        </p>
    
                        <p>
                            After finalizing the VowGlow Project Proposal sketch, I began on a deeper exploration using <b>Mind Mapping.</b>
                            <span className='figure'> (Figure 2)</span>
                             This visual technique helped expand upon initial ideas, assumptions, and project components.
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
                        >
                            <img
                                src={vowglowMindmapping}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 2 (VowGlow Mind Mapping)
                            </p>
                        </div>
                    
                <section key={3} className="section">
                    <div className='text-container'>
                    <h2 id="research" className="section-title" style={{ color: '#FF3E55' }}>Research</h2>
                        <h3>User Interview</h3>
                            <p>In the initial phase of VowGlow, I conducted user interviews with five individuals, identifying challenges like budget management and vendor selection. These insights directly shaped the platform's design to better meet user needs.</p>
                            <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name" style={{ fontSize: '20px', color: '#FF3E55' }}>Participant Demography:</h4>
                                 <ul style={{ fontSize: '18px'}}>
                                    <li>Ages between 25 - 40</li>
                                    <li>Recently engaged couples</li>
                                    <li>Planning to get married within the next 12 months</li>
                                    <li>Recently married couples</li>
                                    <li>Diverse background and geographic locations</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">
                            <h4 className="team-member-name" style={{ fontSize: '20px', color: '#FF3E55' }}>Duration:</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>30 - 45 mins</li>
                                </ul>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FF3E55', // Red background
                                padding: '20px', // Space inside the rectangle
                                textAlign: 'center', // Center the text
                                margin: '20px 0', // Spacing around the rectangle
                                marginBottom: '60px',
                            }}
                            >
                            <p
                                style={{
                                color: '#FFFFFF', // White text
                                fontStyle: 'italic', // Italicized text
                                fontSize: '18px', // Adjust font size
                                lineHeight: '1.5', // Improve readability
                                margin: 0, // Remove default paragraph margin
                                }}
                            >
                                “I feel like I'm <b>drowning</b> in wedding checklists and spreadsheets. <b>Simpler</b> solution would be a lifesaver!”
                            </p>
                            </div>
                            <div className="d-flex flex-column w-50">
                            <h4 className="team-member-name" style={{ fontSize: '20px', color: '#FF3E55' }}>Key Findings/Insights:</h4>
                            </div>
                            <div className="image-inside" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={vowglowFindings}
                                style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            />
                        </div>
                    </div>

                    <div className='text-container'>
                        <h3>Competitive Analysis</h3>
                        <p>
                        To understand the market and identify opportunities for VowGlow, I conducted a <b>competitive analysis</b> of direct wedding planning apps. This provided key insights to guide VowGlow's development and market positioning.
                            <span className='figure'> (Figure 3)</span>
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowcompetitive}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 3 (VowGlow Competitive Analysis)
                            </p>
                            <p></p>
                            <p></p>
                        </div>
                        <p>
                        Through the <b>SWOT analysis</b>, I gained insights into competitors' internal and external factors, identifying their strengths, weaknesses, and potential market opportunities and threats.
                            <span className='figure'> (Figure 4)</span>
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowSWOT}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 4 (VowGlow SWOT Analysis)
                            </p>
                        </div>
                    </div>
                    <div className='text-container'>
                        <h3>User Persona: Jane</h3>
                        <p>
                        I developed the user persona <b>Jane</b><span className='figure'> (Figure 5)</span>for VowGlow, representing a modern bride-to-be seeking convenient, efficient wedding planning solutions. Her persona was based on demographic data, motivations, pain points, and goals identified in user research.
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowPersona}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 5 (VowGlow Competitive Analysis)
                            </p>
                        </div>
                        <h3>Jane's Journey</h3>
                        <p>
                        Jane's journey through the VowGlow app<span className='figure'> (Figure 6)</span>was mapped from discovery to wedding day, covering account creation, using the budget tracker, exploring vendors, and managing RSVPs. This analysis of user behaviors ensured that VowGlow delivers a seamless, personalized wedding planning experience.
                        </p>
                        <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowUserjourney}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 6 (VowGlow Jane’s User Journey)
                            </p>
                        </div>
                    </div>
                </section>

                    </div>

                </section>

                <section key={3} className="section">
                    <div className='text-container' style={{ textAlign: 'left' }}>
                        <h2 id="design" className="section-title" style={{ color: '#FF3E55' }}>Design</h2>
                        <h3>User Flow</h3>
                        <p>VowGlow's user flows<span className='figure'> (Figure 7)</span>were designed to ensure a smooth experience for features like budget tracking, RSVP management, and browsing. These flows guide users through the wedding planning process, making the app easy to use and stress-free.</p>
                    </div>
                    <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowUserflow}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 7 (VowGlow User Flows)
                            </p>
                        </div>
                    <div className='text-container'>
                            <h3>Low Fidelity Wireframes</h3>
                            <p>Low-fidelity wireframes<span className='figure'> (Figure 8)</span>were created to visualize VowGlow’s <b>interface</b> and <b>functionality</b>. This approach focused on basic layout and structure without getting into finer details.</p>

                    </div>
                    <div className='grey-bg' style={{ padding: '10px' }}>
                        <div className='text-container'>
                            <div className="image-inside"style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowLowFi}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 8 (VowGlow Lo-Fi Wireframes)
                            </p>          
                            </div>
                        </div>
                    </div>
                    <div className='text-container' style={{ textAlign: 'left' }}>
                        <h3>Design System</h3>
                        <p>The VowGlow design system<span className='figure'> (Figure 9)</span>was developed to establish components and standards for visual and functional consistency, including typography, color palettes, iconography, and interaction patterns.</p>
                    </div>
                    <div
                            className="image-inside"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowDesignsystem}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 9 (VowGlow Design System)
                            </p>
                        </div>
                        <div className='text-container'>
                            <h3>Mid-Fidelity Wireframes</h3>
                            <p>I then created mid-fidelity wireframes<span className='figure'> (Figure 10)</span>, translating the low-fidelity concepts into more detailed designs. Focusing on <b>visual aesthetics</b> and <b>interaction</b>, the wireframes were refined to align with the design system and user experience goals.</p>

                    </div>
                    <div className='grey-bg' style={{ padding: '10px' }}>
                        <div className='text-container'>
                            <div className="image-inside"style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglowMidFi}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />
                            <p
                                className="figure"
                            >
                                Figure 10 (VowGlow Mid-Fi Wireframes)
                            </p>          
                            </div>
                        </div>
                    </div>
                </section>

                <section key={4} className="section">
                    <div className='text-container'>
                        <h2 id="iteration" className="section-title" style={{ color: '#FF3E55' }}>Iteration</h2>
                        <p>After developing the mid-fidelity prototype using the mid-fidelity wireframes, I conducted <b>usability testing</b> to gather valuable user feedback and insights.</p>
                        <h3>Methodology</h3>
                        <div className="d-flex flex-column w-50">
                        <h4 className="team-member-name" style={{ fontSize: '20px', color: '#FF3E55' }}>Qualitative Research: Usability Testing</h4>
                            <ul style={{ fontSize: '18px' }}>
                                <li><b>Number of Participants:</b>  5</li>
                                <li><b>Ratio:</b>  1 interviewer : 1 participant </li>
                                <li><b>Interview Time:  </b>45 - 60 mins</li>
                            </ul>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'left', // Centers the image horizontally
                                alignItems: 'left', // Centers the image vertically if the div has height
                            }}
                            >
                            <img
                                src={vowglowResults}
                                className="image-inside"
                                alt="VowGlow Proposal"
                                style={{
                                width: '100%', // Set the image width to 50% of its container
                                maxWidth: '750px', // Limit the maximum width to 300px
                                height: 'auto', // Maintain the aspect ratio
                                }}
                            />
                            </div>
                        <h3>Key Findings/Refinements</h3>
                        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src={iteration1} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration2} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration3} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration4} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration5} class="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" >
                                <span className="carousel-control-next-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                </section>
                <section key={5} className="section lightpink-bg">
                    <div className='text-container'>
                        <h2 id="product" className="section-title" style={{ color: '#FF3E55' }}>Product</h2>
                        <iframe style={{ paddingTop: '30px' }} width="100%" height="800" 
                        src="https://embed.figma.com/proto/23tCOv5Pop7X99lgAiqoVE/MICA-Capstone-%7C-VowGlow?type=design&node-id=1004-16626&scaling=scale-down&page-id=1004%3A10336&starting-point-node-id=1004%3A16626&embed-host=share" allowfullscreen></iframe>
                    </div>
                </section>
                <section key={6} className="section">
                    <div className='text-container'>
                        <h2 id="next-step" className="section-title"style={{ color: '#FF3E55' }}>Reflection & Next Step</h2>
                        <h3>Reflection</h3>
                        <ul>
                            <li><p><b>Balancing Innovation and Simplicity:</b> Overcame the challenge of integrating advanced features like AI virtual try-on while maintaining a user-friendly design within an 8-week timeline.</p></li>
                            <li><p><b>Resource Constraints:</b> Managed all aspects of the project independently, including UX research, wireframing, prototyping, and usability testing.</p></li>
                            <li><p><b>Key Learnings:</b> Importance of incorporating user feedback to prioritize user needs and make effective design decisions.
                                Value of iterative design to continuously refine and improve the user experience.</p></li>
                        </ul>
                    </div>
                    <div className='text-container'>
                        <h3>Next Step</h3>
                        <ul>
                            <li><p><b>Refining the Browse Section:</b> Add advanced search, personalized recommendations, and a Virtual Tour feature for venues to save users time. <span className='figure'> (Figure 11)</span></p></li>
                            <li><p><b>Third-Party APIs:</b> Address costs and prove the value of features like Virtual Try-On.</p></li>
                            <li><p><b>A/B Testing:</b> Prepare for A/B testing to improve user engagement and drive sales conversions. This involves creating multiple design variations and committing to iterative improvements while understanding the evolution of software products. <span className='figure'> (Figure 12)</span></p></li>
                        </ul>
                    </div>
                    <div className="image-inside"style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={vowglownextstep}
                                className="image-with-comment smaller-img"
                                alt="VowGlow Proposal"
                            />       
                            </div>

                </section>
            </div>
        </div>
    );
}

export default VowGlow;
